import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";

import { HOME_HEALTH_APP_EVENTS } from "../../lib/events";
import { type SignaturesResponse, signaturesResponseSchema } from "../../types";

export const SIGNATURES_URL = `${environmentConfig.REACT_APP_HOME_HEALTH_API_URL}/v1/signatures`;

export function getSignaturesQueryKey() {
  return [SIGNATURES_URL];
}

export function useSignatures(options: UseGetQueryOptions<SignaturesResponse> = {}) {
  return useGetQuery({
    url: SIGNATURES_URL,
    responseSchema: signaturesResponseSchema,
    meta: {
      logErrorMessage: HOME_HEALTH_APP_EVENTS.GET_SIGNATURES_FAILURE,
    },
    retry: 0,
    staleTime: 1000 * 60 * 1,
    ...options,
  });
}
