import { Alert, Box, Button, LinearProgress, Stack } from "@mui/material";
// eslint-disable-next-line no-restricted-imports
import {
  SignatureContainer,
  type SignatureContainerRef,
} from "@src/app/shiftSignature/signatureContainer";
import { useRef, useState } from "react";

export interface SignatureConfirmationProps {
  isLoading: boolean;
  isError: boolean;
  onCancel: () => any;
  onSubmit: (fileBase64: string) => any;
  onChange: (fileBase64: string) => any;
}

export function SignatureConfirmation(props: SignatureConfirmationProps) {
  const { onSubmit, onChange, onCancel, isLoading, isError } = props;

  const signaturePad = useRef<SignatureContainerRef>(null);
  const [signature, setSignature] = useState("");

  return (
    <Stack alignItems="center" spacing={1}>
      <Button
        disabled={isLoading || signature === ""}
        onClick={() => {
          signaturePad?.current?.clearSignature();
          onChange("");
          setSignature("");
        }}
      >
        Clear Signature
      </Button>
      <Box border="1px solid black">
        <SignatureContainer
          ref={signaturePad}
          signature={signature}
          onChange={(updatedSignature: string) => {
            onChange(updatedSignature);
            setSignature(updatedSignature);
          }}
        />
      </Box>
      {isError && <Alert severity="error">Error submitting signature</Alert>}
      <Stack>
        <Button
          disabled={isLoading || signature === ""}
          sx={{ overflow: "hidden", position: "relative" }}
          variant="contained"
          onClick={async () => {
            onSubmit(signature);
          }}
        >
          {isLoading && (
            <LinearProgress
              color="info"
              sx={{ position: "absolute", bottom: 0, left: 0, width: "100%", height: "5px" }}
            />
          )}
          I Understand and Agree
        </Button>
        <Button disabled={isLoading} onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}
