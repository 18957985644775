import { InternalLink, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Button } from "@mui/material";
import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { ACCOUNTS_DOCUMENTS_FULL_PATH } from "@src/appV2/Accounts/Documents/paths";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { MY_SHIFTS_PATH } from "@src/appV2/Shifts/MyShifts";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { type Case, type Visit, VisitStatus } from "../types";
import { BookVisitDialog } from "./BookVisitDialog";
import { MissingDocumentsDialogContent } from "./MissingDocumentsDialogContent";

interface Props {
  visit: Visit;
  caseItem: Case;
}

export function OpenCaseVisitActionButton({ visit, caseItem }: Props) {
  const bookModalState = useModalState();
  const history = useHistory();
  const isVisitRequirementCheckEnabled = useCbhFlag(
    CbhFeatureFlag.HOME_HEALTH_VISIT_REQUIREMENT_CHECK,
    { defaultValue: false }
  );

  const missingDocumentDialogState = useModalState();

  if (visit.attributes.status !== VisitStatus.OPEN) {
    return (
      <InternalLink to={`${MY_SHIFTS_PATH}?searchedVisitId=${visit.id}`}>
        <Button
          variant="contained"
          size="small"
          sx={{ minWidth: "100px", maxHeight: "32px" }}
          color="success"
        >
          Booked
        </Button>
      </InternalLink>
    );
  }

  const { missingRequirements } = visit.attributes;
  if (
    !isVisitRequirementCheckEnabled ||
    !isDefined(missingRequirements) ||
    missingRequirements.length === 0
  ) {
    return (
      <>
        <Button
          variant="contained"
          size="small"
          sx={{ minWidth: "100px", maxHeight: "32px" }}
          onClick={bookModalState.openModal}
        >
          Book
        </Button>
        <BookVisitDialog modalState={bookModalState} visit={visit} caseItem={caseItem} />
      </>
    );
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{ minWidth: "100px", maxWidth: "170px", maxHeight: "64px" }}
        onClick={missingDocumentDialogState.openModal}
      >
        Upload {missingRequirements.length} {pluralize("Doc", missingRequirements.length)}
      </Button>
      <MissingDocumentsDialogContent
        modalState={missingDocumentDialogState}
        missingDocuments={missingRequirements}
        onConfirm={(missingDocumentsDetails) => {
          if (missingDocumentsDetails.length === 1) {
            history.push(
              generateDocumentDetailsPath({
                hcfId: caseItem.attributes.patient.attributes.workplaceId,
                requirement: missingDocumentsDetails[0].reqId,
              })
            );
          } else {
            history.push(ACCOUNTS_DOCUMENTS_FULL_PATH);
          }
        }}
      />
    </>
  );
}
