import { Divider, List, ListItem } from "@mui/material";
import { Fragment } from "react";

import { HomeHealthCaseCard } from "../lib/CaseCard";
import { sortVisitsByDateAndType } from "../lib/sortVisitByDateAndType";
import { type Case } from "../types";
import { OpenCaseVisit } from "./OpenCaseVisit";

interface Props {
  caseItem: Case;
}

export function OpenCase(props: Props) {
  const { caseItem } = props;

  return (
    <HomeHealthCaseCard
      caseItem={caseItem}
      content={
        <List>
          {[...caseItem.attributes.visits].sort(sortVisitsByDateAndType).map((visit) => (
            <Fragment key={visit.id}>
              <Divider />
              <ListItem disableGutters>
                <OpenCaseVisit visit={visit} caseItem={caseItem} />
              </ListItem>
            </Fragment>
          ))}
        </List>
      }
    />
  );
}
