import { patch } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { logEvent } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { getBookedCasesQueryKey } from "../../BookedCases/api/useBookedCases";
import { HOME_HEALTH_USER_EVENTS } from "../../lib/events";
import {
  type BookVisitRequest,
  bookVisitRequestSchema,
  bookVisitResponseSchema,
  type CasesResponse,
  CaseStatus,
  type Visit,
} from "../../types";
import { getOpenCasesQueryKey } from "./useOpenCases";

export const BOOK_VISIT_URL = `${environmentConfig.REACT_APP_HOME_HEALTH_API_URL}/v1/visits/:visitId`;

export function useBookVisit(
  visitId: string,
  options: UseMutationOptions<Visit, string, BookVisitRequest> = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      const response = await patch({
        url: BOOK_VISIT_URL.replace(":visitId", visitId),
        data: params,
        requestSchema: bookVisitRequestSchema,
        responseSchema: bookVisitResponseSchema,
      });
      return response.data.data;
    },
    onSuccess: (visit) => {
      const caseQueries = queryClient.getQueriesData<CasesResponse>(
        getOpenCasesQueryKey().slice(0, 1)
      );
      const cases = caseQueries.flatMap(([_, queryData]) => queryData?.data);
      const caseForVisit = cases.find((caseItem) => caseItem?.id === visit.attributes.caseId);
      logEvent(HOME_HEALTH_USER_EVENTS.VISIT_BOOKED, {
        caseId: visit.attributes.caseId,
        visitId: visit.id,
        patientId: caseForVisit?.attributes.patient.id,
        workplaceId: caseForVisit?.attributes.workplace.id,
        workerReq: visit.attributes.workerReq,
        type: visit.attributes.type,
      });
      void queryClient.invalidateQueries(getOpenCasesQueryKey().slice(0, 1));
      void queryClient.invalidateQueries(
        getBookedCasesQueryKey({ filter: { status: CaseStatus.OPEN } })
      );
    },
    ...options,
  });
}
