import { Stack } from "@mui/material";

import { HomeHealthCaseVisitDetails } from "../lib/CaseVisitDetails";
import { type Case, type Visit } from "../types";
import { OpenCaseVisitActionButton } from "./OpenCaseVisitActionButton";

interface Props {
  visit: Visit;
  caseItem: Case;
}

export function OpenCaseVisit(props: Props) {
  const { visit, caseItem } = props;

  return (
    <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
      <HomeHealthCaseVisitDetails visit={visit} instantPay={caseItem.attributes.instantPay} />

      <OpenCaseVisitActionButton visit={visit} caseItem={caseItem} />
    </Stack>
  );
}
