import {
  ExternalLink,
  Li,
  Span,
  Text,
  Title,
  Ul,
  type UseModalState,
} from "@clipboard-health/ui-react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, CircularProgress, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { STATE_LICENSE_REQUIREMENT_ID } from "@src/constants";
import pluralize from "pluralize";

import { type VisitMissingRequirements } from "../types";

export interface MissingDocument {
  reqId: string;
  name: string;
  visibleToHCP: boolean;
}

interface MissingDocumentsDialogContentProps {
  missingDocuments: MissingDocument[];
  onConfirm: (missingDocuments: MissingDocument[]) => void;
  isLoading?: boolean;
  modalState: UseModalState;
}

export function MissingDocumentsDialogContent(props: MissingDocumentsDialogContentProps) {
  const { modalState, missingDocuments, isLoading, onConfirm } = props;

  const visibleMissingDocuments: VisitMissingRequirements[] = [];
  const invisibleMissingDocuments: VisitMissingRequirements[] = [];

  missingDocuments.forEach((requirement) => {
    if (requirement.reqId === STATE_LICENSE_REQUIREMENT_ID) {
      visibleMissingDocuments.push({
        ...requirement,
        name: "State License (upload as Miscellaneous)",
      });
    } else if (requirement.visibleToHCP) {
      visibleMissingDocuments.push(requirement);
    } else {
      invisibleMissingDocuments.push(requirement);
    }
  });

  const MAX_SHOWN_DOCUMENTS = 6;
  const hasMoreDocuments =
    visibleMissingDocuments.length > MAX_SHOWN_DOCUMENTS ||
    invisibleMissingDocuments.length > MAX_SHOWN_DOCUMENTS;

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <Stack alignItems="center">
          <AddCircleIcon color="primary" fontSize="large" />
          <Title bold component="h1">
            You&apos;re Missing{" "}
            <Span color={(theme) => theme.palette.primary.main}>{missingDocuments.length}</Span>{" "}
            {pluralize("Document", missingDocuments.length)}
          </Title>
        </Stack>
      }
      actions={
        <Stack width="100%" spacing={1}>
          {visibleMissingDocuments.length > 0 && (
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onConfirm(missingDocuments);
              }}
            >
              Upload {pluralize("Docs", missingDocuments.length)}
            </Button>
          )}
          <Button fullWidth variant="outlined" onClick={modalState.closeModal}>
            Close
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2}>
        {isLoading && <CircularProgress color="inherit" size="1.2rem" sx={{ mx: 1 }} />}
        {visibleMissingDocuments.length > 0 && (
          <Stack>
            <Text>
              To complete your booking, the following{" "}
              {pluralize("document", missingDocuments.length)} must be uploaded and approved:
            </Text>
            <Ul>
              {visibleMissingDocuments.slice(0, MAX_SHOWN_DOCUMENTS).map((document) => (
                <Li key={document.reqId}>
                  <Text bold>{document.name}</Text>
                </Li>
              ))}
            </Ul>
          </Stack>
        )}
        {invisibleMissingDocuments.length > 0 && (
          <Stack>
            <Text>
              We will process the following requirements for you. Please contact{" "}
              <ExternalLink to="mailto:onboarding@clipboardhealth.com">
                onboarding@clipboardhealth.com
              </ExternalLink>{" "}
              if you have any questions:
            </Text>
            <Ul>
              {invisibleMissingDocuments.slice(0, MAX_SHOWN_DOCUMENTS).map((document) => (
                <Li key={document.reqId}>
                  <Text bold>{document.name}</Text>
                </Li>
              ))}
            </Ul>
          </Stack>
        )}
        {hasMoreDocuments && (
          <Text align="center">
            Click to upload your documents and view the remaining missing requirements.
          </Text>
        )}
      </Stack>
    </CommonDialog>
  );
}
