import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";

import { HOME_HEALTH_APP_EVENTS } from "../../lib/events";
import {
  type CaseFilters,
  caseFiltersSchema,
  type CasesResponse,
  casesResponseSchema,
  CaseStatus,
} from "../../types";

export const OPEN_CASES_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/home-health/cases`;

export function getOpenCasesQueryKey(filters: CaseFilters = {}) {
  return [OPEN_CASES_URL, filters];
}

export function useOpenCases(
  filters: CaseFilters,
  options: UseGetQueryOptions<CasesResponse> = {}
) {
  const filtersWithDefaults = {
    ...filters,
    filter: { booked: false, status: CaseStatus.OPEN, ...filters.filter },
  };
  return useGetQuery({
    url: OPEN_CASES_URL,
    queryParams: filtersWithDefaults,
    requestSchema: caseFiltersSchema,
    responseSchema: casesResponseSchema,
    meta: {
      logErrorMessage: HOME_HEALTH_APP_EVENTS.GET_OPEN_CASES_FAILURE,
    },
    retry: 0,
    staleTime: 1000 * 60 * 1,
    refetchInterval: 1000 * 60 * 1,
    ...options,
  });
}
